:root {
  --header-height: 50px;
}

@media (max-width:1079px){
  .show {
    display: block;
    transform: translateX(240px);
  }
  .menu-button {
    display: inline-block;
    position: relative;
    width: var(--header-height);
    height: var(--header-height);
    border: none;
  }
  .menu-button span {
    position: absolute;
    width: 30px;
    left: 5px;
    height: 3px;
    transition: transform .3s;
  }
  .menu-button span:nth-child(1) {
    transform: translateY(-13px);
  }
  .menu-button span:nth-child(2) {
    transform: translateY(-3px);
  }
  .menu-button span:nth-child(3) {
    transform: translateY(7px);
  }
  .open span:nth-child(1) {
    transform: translateY(-3px) rotate(45deg);
  }
  .open span:nth-child(2) {
    transform: translateY(-3px);
    opacity: 0;
  }
  .open span:nth-child(3) {
    transform: translateY(-3px) rotate(-45deg);
  }
  .table-render {
    width: 100%;
    overflow-x: auto;
  }
  .vertical-menu-content {
    left: -240px;
    transition: transform .3s;
    will-change: transfrom;
  }
  .main-content {
    margin-top: calc(var(--header-height) + 10px);
    flex-direction: column;
  }
  .adv-side {
    width: 100%;
  }
  .main-content-text{
    padding: 15px;
  }
}
@media (min-width:500px){
  code::-webkit-scrollbar {
    height: 10px;
    border-radius: 10px;
  }

  code::-webkit-scrollbar-track {
    background: transparent;
    width:5px;
    border-radius: 10px;
  }
  code::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  .vertical-menu-content::-webkit-scrollbar {
    width: 12px;
    border-radius: 10px;
  }

  .vertical-menu-content::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .vertical-menu-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
}
@media (min-width:1080px){
  .menu-button {
    display: none;
  }
  .vertical-menu-content {
    left:0;
  }

  .main-content {
    display: flex;
    margin-top: calc(var(--header-height) + 10px);
    flex-direction: row;
  }
  .main-content-text {
    max-width: 600px;
  }
  .adv-side {
    width: 100%;
    min-width: 240px;
  }
}
body {
  margin:0;
  padding:0;
  font-family: Roboto, serif;
  font-size:14px;
  transition: all .3s;
}
header{
  font-family:Roboto, serif;
  margin-bottom:0px;
  height: var(--header-height);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
}
.vertical-menu-content{
  height:calc(100% - var(--header-height) - 2px);
  width:240px;
  position:fixed;
  top:calc(var(--header-height) + 2px);
  overflow-y: auto;
  display: block;
  z-index: 10;
}

.item{
  margin: 5px;
  display: block;
  padding: 10px;
  text-decoration: none;
  border-radius: 20px;
}
.render{
  padding-top: 130px;
  padding-left: 15px;
  padding-right: 15px;
}
table{
  text-align: left;
  border-collapse: collapse;
}
td, th{
  padding: 3px;
}
code {
  font-weight: bold;
}
.selected {
  background-color: lightgreen;
  color: black;
}
.copy-button {
  margin: 0;
  font-weight: bold;
}

pre {
  margin-top: 0;
}
pre, code {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
footer {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
section {
  margin-bottom: 20px;
  padding: 20px;
}
.chapter {
  padding: 15px 5px 15px 10px;
  font-size: 14px;
}
.theme-switcher {
  background-size: cover;
  padding: 20px;
  border: none;
  background-color: transparent;
  display: block;
  width: 50px;
}
.tutorial {
  padding:20px;
  border-radius: 5px;
}
.navigation-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.navigation-panel a, .sub-navigation-panel a {
  display: block;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 2px;
  text-align: center;
}
.navigation-panel a:hover, .sub-navigation-panel a:hover {
  font-weight: bold;
}
.sub-navigation-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .navigation-panel {
    flex-direction: column;
  }
  .sub-navigation-panel {
    width: 100%;
  }
  .navigation-panel a, .sub-navigation-panel a {
    width: 100%;
  }

}
.header {
  display: block;
  font-size: 18px;
  text-decoration: none;
  margin-top: 10px;
  justify-content: center;
}
.header:hover {
  font-weight: bold;
}
@media (hover: none) {
  .header:active, .navigation-panel a:active, .sub-navigation-panel a:active  {
    font-weight: bold;
  }
}
* {
   font-family: Roboto, monospace;
 }
.header-container {
  display: flex;
}