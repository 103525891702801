
nav ul {
    list-style: none;
}
nav ul li {
    display: inline;
    margin-right: 20px;
}
nav ul li a {
    color: #fff;
    text-decoration: none;
}
#hero {
    text-align: center;
}

#tutorials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}
.card {
    padding: 10px 20px 20px 20px;
    margin: 15px;
    border-radius: 10px;
    transition: transform 0.2s, box-shadow 0.2s;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 150px;
}
.card:hover {
    transform: translateY(-5px);
}
.card-title {
    font-size: 20px;
}
.card-button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    margin-top:10px;

}

.card-button:hover {
    transform: scale(1.05);
}